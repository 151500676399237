import React, { useState, useEffect } from "react";
import "./App.css";
import { BpmInput } from "./components/BpmInput";
import { SuggestedSpeedMods } from "./components/SuggestedSpeedMods";
import { version } from "../package.json";

// https://remywiki.com/DDR_AC_General_Info#SPEED
const DDRA_SPEED_PREMIUM_MODS = [
  0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75,
  4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8,
];

const DDRA_SPEED_NORMAL_MODS = [
  1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8,
];

const DDR_EXTREME_PRO_SPEED_MODS = [0.5, 1, 1.5, 2, 3, 5, 8];

type SelectGameProps = {
  setGame: any;
};

const DDRA_PREMIUM_KEY = "ddra_premium";
const DDRA_NORMAL_KEY = "ddra_normal";
const DDREX_PRO_KEY = "ddrex_pro";

type DDR_GAME_KEY = "ddra_premium" | "ddra_normal" | "ddrex_pro";

function determineModSet(key: DDR_GAME_KEY) {
  if (key === DDRA_PREMIUM_KEY) {
    return DDRA_SPEED_PREMIUM_MODS;
  }
  if (key === DDRA_NORMAL_KEY) {
    return DDRA_SPEED_NORMAL_MODS;
  }
  if (key === DDREX_PRO_KEY) {
    return DDR_EXTREME_PRO_SPEED_MODS;
  }
  //defualt
  return DDRA_SPEED_PREMIUM_MODS;
}

function SelectGame(props: SelectGameProps) {
  const { setGame } = props;
  const DDRA_PREMIUM = (
    <option key={DDRA_PREMIUM_KEY} value={DDRA_PREMIUM_KEY}>
      DDR A Premium
    </option>
  );
  const DDRA_NORMAL = (
    <option key={DDRA_NORMAL_KEY} value={DDRA_NORMAL_KEY}>
      DDR A Normal
    </option>
  );
  const DDREX_PRO = (
    <option key={DDREX_PRO_KEY} value={DDREX_PRO_KEY}>
      DDR Extreme (Pro)
    </option>
  );

  const [selectedGame, setSelectedGame] =
    useState<DDR_GAME_KEY>(DDRA_PREMIUM_KEY);

  const selectGame = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as DDR_GAME_KEY;
    localStorage.setItem(SAVED_GAME, value);
    setSelectedGame(value);

    const modSet = determineModSet(value);
    setGame(modSet);
  };

  useEffect(() => {
    const previousSavedGame = localStorage.getItem(SAVED_GAME);

    if (previousSavedGame) {
      const modSet = determineModSet(previousSavedGame as DDR_GAME_KEY);
      setGame(modSet);
      setSelectedGame(previousSavedGame as DDR_GAME_KEY);
    }
  }, [setGame]);

  return (
    <form className="GameForm">
      <label>
        Game:
        <select
          className="GameSelect"
          onChange={selectGame}
          value={selectedGame}
        >
          {DDRA_PREMIUM}
          {DDRA_NORMAL}
          {DDREX_PRO}
        </select>
      </label>
    </form>
  );
}

const READ_SPEED = "readSpeed";
const SONG_BPM = "songBpm";
const SAVED_GAME = "savedGame";

function MainPage() {
  const [readSpeed, setReadSpeed] = useState(0);
  const [songBpm, setSongBpm] = useState(0);
  const [selectedGame, setSelectedGame] = useState(DDRA_SPEED_PREMIUM_MODS);

  useEffect(() => {
    const previousReadSpeed = localStorage.getItem(READ_SPEED);
    const previousSongBpm = localStorage.getItem(SONG_BPM);

    if (previousReadSpeed) {
      setReadSpeed(Number(previousReadSpeed));
    }
    if (previousSongBpm) {
      setSongBpm(Number(previousSongBpm));
    }
  }, []);

  return (
    <div className="App">
      <div className="App-body">
        <div className="ResultSection">
          <SuggestedSpeedMods
            desiredReadSpeed={readSpeed}
            songBpm={songBpm}
            modList={selectedGame}
          />
        </div>
        <div className="InputSection">
          <BpmInput
            bpm={readSpeed}
            name={READ_SPEED}
            label="Desired Read Speed:"
            handleUpdate={setReadSpeed}
          />
          <BpmInput
            bpm={readSpeed}
            name={SONG_BPM}
            label="Song BPM:"
            handleUpdate={setSongBpm}
          />
          <SelectGame setGame={setSelectedGame} />
        </div>
        <div className="App-footer">
          <div className="footer-text">
            Made with{" "}
            <span role="img" aria-label="Green Heart">
              💚
            </span>
            for the DDR Community{" "}
            <span role="img" aria-label="Left, Down, Up, Right">
              ⬅️⬇️⬆️➡️
            </span>
            <div style={{ color: "white" }}>
              Version {version}, updated 10/19/23
            </div>
            <div>
              By Dexor{" "}
              <a href="https://twitter.com/dexor">
                <span role="img" aria-label="Bird">
                  🐦
                </span>
              </a>
              <a href="https://bsky.app/profile/dexor.me">
                <span role="img" aria-label="Cloud">
                  ⛅
                </span>
              </a>
              <a href="https://mastodon.world/@dexor">
                <span role="img" aria-label="Elephant">
                  🐘
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const App: React.FC = () => {
  return <MainPage />;
};

export default App;
