import React from "react";
import "./BpmInput.css";

export type BpmInputProps = {
  bpm: number;
  handleUpdate: any;
  label: string;
  name: string;
};

export function BpmInput(props: BpmInputProps) {
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
      }}
      className="InputForm"
    >
      <label>{props.label}</label>
      <input
        className={props.name}
        name={props.name}
        type="number"
        inputMode="numeric"
        min={0}
        max={1000}
        defaultValue={localStorage.getItem(props.name) ?? undefined}
        onBlur={event => {
          props.handleUpdate(event.target.value);
          localStorage.setItem(props.name, event.target.value);
        }}
      />
    </form>
  );
}
