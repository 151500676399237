import React from "react";
import { calculateGameMod } from "../util/calculateMod";
import { Result } from "./Result";
import "./SuggestedSpeedMods.css";

export type SpeedModProps = {
  desiredReadSpeed: number;
  songBpm: number;
  modList: number[];
};

export function SuggestedSpeedMods(props: SpeedModProps) {
  const { desiredReadSpeed, songBpm, modList } = props;

  if (desiredReadSpeed === 0 || songBpm === 0) {
    return <div className="NoValuesEntered">Enter some BPMs!</div>;
  }

  const approxMod = desiredReadSpeed / songBpm;

  const mod = calculateGameMod(approxMod, modList);

  if (mod.exactMod) {
    return (
      <div className="ThreeResults">
        <Result
          className="Result"
          mod={mod.lowerMod}
          bpm={songBpm * mod.lowerMod}
        />
        <Result
          className="ExactResult"
          mod={mod.exactMod}
          bpm={songBpm * mod.exactMod}
        />
        <Result
          className="Result"
          mod={mod.upperMod}
          bpm={songBpm * mod.upperMod}
        />
      </div>
    );
  }
  return (
    <div className="TwoResults">
      <Result
        className="Result"
        mod={mod.lowerMod}
        bpm={songBpm * mod.lowerMod}
      />
      <Result
        className="Result"
        mod={mod.upperMod}
        bpm={songBpm * mod.upperMod}
      />
    </div>
  );
}
