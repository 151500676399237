import React from "react";

export type ResultProps = {
  mod: number;
  bpm: number;
  className?: string;
};

export function Result(props: ResultProps) {
  const { mod, bpm } = props;

  return (
    <div className={props.className}>
      <div>x{mod}</div>
      <div style={{ color: "rgb(200, 255, 0)" }}>{bpm}</div>
    </div>
  );
}
