export type CalculatedSpeedMods = {
  upperMod: number;
  lowerMod: number;
  exactMod?: number;
};

export function calculateGameMod(approxMod: number, speedMods: number[]) {
  let calculatedSpeedmods: CalculatedSpeedMods = {
    upperMod: 0,
    lowerMod: 0
  };

  for (let i = 0; i < speedMods.length; i++) {
    let mod = speedMods[i];
    // The approxMod is already the suggested mod
    if (approxMod === mod) {
      calculatedSpeedmods.exactMod = mod;
      // Don't go outside the upper limit of the mods, you speed demon you.
      calculatedSpeedmods.upperMod =
        speedMods[i + 1 > speedMods.length ? speedMods.length : i + 1];
      // Don't go slower than what is possible, why would you do this?
      calculatedSpeedmods.lowerMod = speedMods[i - 1 < 0 ? 0 : i - 1];
      return calculatedSpeedmods;
    }

    if (mod > approxMod) {
      // Don't go slower than what is possible, why would you do this?
      calculatedSpeedmods.lowerMod = speedMods[i - 1 < 0 ? 0 : i - 1];
      calculatedSpeedmods.upperMod = speedMods[i];
      return calculatedSpeedmods;
    }
  }

  //It's just not fast enough for you, return the fastest possible mod
  calculatedSpeedmods.exactMod = speedMods[speedMods.length - 1];
  calculatedSpeedmods.upperMod = speedMods[speedMods.length - 1];
  calculatedSpeedmods.lowerMod = speedMods[speedMods.length - 2];
  return calculatedSpeedmods;
}
